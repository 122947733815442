// src/components/AcessoZap.js

import React, { useState, useEffect } from 'react';
import './App.css';
import Swal from 'sweetalert2';

const AcessoZap = () => {
  const [nome, setNome] = useState('');
  const [ipAddress, setIpAddress] = useState('');

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await fetch('https://api.ipify.org/?format=json');
        const data = await response.json();
        setIpAddress(data.ip);
      } catch (error) {
        console.error('Erro ao obter o IP do usuário:', error);
      }
    };

    fetchIpAddress();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await fetch('https://formspree.io/f/xkndvjna', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ nome, ipAddress }),
      });

      setNome('');

      Swal.fire({
        icon: 'success',
        title: 'Ei...',
        text: 'Isso é aviso! Entendeu?',
        confirmButtonText: 'Sim, entendi!',
      }).then(() => {
        window.location.href = 'api/link.php';
      });
    } catch (error) {
      console.error('Erro ao enviar dados para Formspree:', error);
    }
  };

  return (
    <form id="formulario" onSubmit={handleSubmit}>
      <input type="hidden" name="_language" value="pt-BR" />

      <h1>Zap - ᴏs ғɪʟᴍᴇs/sᴇ́ʀɪᴇs 🍿🍟🍕🥤🍔</h1>
      <p>
        <label htmlFor="nomeAcesso">Seu nome
          <input
            id="nomeAcesso"
            name="nome"
            required
            type="text"
            placeholder=""
            value={nome}
            onChange={(e) => setNome(e.target.value)}
          />
        </label>
      </p>
      <p className="txtAtencao">
        <strong style={{ fontSize: '20px' }}>Atenção!</strong><br />
        Não nos responsabilizamos por menores de idade, se você entrar no grupo.<br />
        Lembre-se de que não podemos fazer nada a respeito.
        <br /><br />
        E outra coisa, se você ficar observando todos os dias sem responder, será expulso(a)<br />
        depois de 3 meses, se tiver somente 10 mensagens.
      </p>
      <p>
        <button type="submit" className="txtButton">Acessar</button>
      </p>
    </form>
  );
};

export default AcessoZap;