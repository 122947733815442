// src/App.js

import React from 'react';
import AcessoZap from './components/AcessoZap';

function App() {
  return (
    <div className="container">
      <div className="content">
        <div id="login">
          <AcessoZap />
        </div>
      </div>
    </div>
  );
}

export default App;